import React from "react";
import styled from "styled-components";
import SEO from "../components/seo";

const providerData = [
  {
    entity: "DigitalOcean LLC z siedzibą w USA",
    why: "Na serwerze tej firmy działa nasz program obsługujący zamówienia. Nie przechowujemy tu danych na stałe. Twoje" +
      "dane przepływają przez nasz program i są wysyłane na naszą skrzynkę pocztową."
  },
  {
    entity: "Amazon Web Services EMEA SARL spółka z ograniczoną odpowiedzialnością Oddział w Polsce",
    why: "Wysłanie maila z Twoim zamówieniem na naszą skrzynkę pocztową."
  },
  {
    entity: "Operator DHL Express (Poland) sp. z o.o. z siedzibą w Warszawie",
    why: "Realizacja zamówienia"
  }
]

const Table = styled.table`
  td {
    padding: 12px;
  }
  
  thead tr td {
    font-weight: bold;
    border-bottom: 3px black solid;
  }
  
  tr td {
    border-bottom: 1px black solid;
  }
`

const ServiceProviders = () => {
  return (
    <>
      <SEO title={"Dostawcy usług"} />
      <p>Lista dostawców usług, z których korzystamy przy prowadzeniu Sklepu Internetowego</p>
      <Table>
        <thead>
          <tr>
            <td>Podmiot</td>
            <td>Cel</td>
          </tr>
        </thead>
        <tbody>
        {
          providerData.map(provider => (
            <tr key={provider.entity}>
              <td>{provider.entity}</td>
              <td>{provider.why}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </>
  );
}

export default ServiceProviders;